import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';
import * as z from 'zod';

import { HomeLogo } from '@components/HomeLogo';

import { ApiRoutes, Routes, TrackingEventType } from '@constants/index';

import { MoveType } from '@features/profile/profile.types';

import { useEventLogger } from '@hooks/useEventLogger';

import axios from '@utils/customAxios';

import { ConfirmAccount } from './steps/ConfirmAccount';
import { CurrentAddressStep } from './steps/CurrentAddress';
import { EmailStep } from './steps/Email';
import { MoveTypeStep } from './steps/MoveType';
import { MovingSoonStep } from './steps/MovingSoon';
import { NameStep } from './steps/Name';
import { NextAddressStep } from './steps/NextAddress';

export type NewUser = {
  sessionId: string;
  firstName: string;
  lastName: string;
  email: string;
  moveType: MoveType;
  currentAddress: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    postcode: string;
    searchTerm?: string;
    selectedAddress?: string;
  };
  nextAddress?: {
    addressLine1: string;
    addressLine2?: string;
    city: string;
    postcode: string;
    searchTerm?: string;
    selectedAddress?: string;
  };
  terms: Date;
};

const propertySchema = z.object({
  addressLine1: z.string().min(1, 'Address line 1 required'),
  addressLine2: z.string().optional(),
  city: z.string().min(1, 'City is required'),
  postcode: z.string().min(1, 'Postcode is required'),
  searchTerm: z.string().optional(),
  selectedAddress: z.string().optional(),
});

const schema = (isMoving: boolean) => z
  .object({
    sessionId: z.string().min(1, 'Session required'),
    firstName: z.string().min(1, 'First name is required'),
    lastName: z.string().min(1, 'Last name is required'),
    email: z.string().email({ message: 'Invalid email' }).min(1, 'Email is required'),
    currentAddress: propertySchema,
    nextAddress: propertySchema.optional(),
    terms: z.date({ required_error: 'Terms are required' }),
    moveType: z.string().min(1, 'Move status is required'),
  })
  .superRefine((data, ctx) => {
    if (data.moveType === MoveType.BUYER && isMoving && !data.nextAddress) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'New address is required when moving',
        path: ['nextAddress'],
      });
    }
  });

export const SignupPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isMoving, setIsMoving] = useState<boolean>(true);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');

  const { logEvent } = useEventLogger();

  const totalSteps = 7;

  const methods = useForm<NewUser>({
    mode: 'onChange',
    resolver: async (data, context, options) => {
      const resolver = zodResolver(schema(isMoving));
      const result = await resolver(data, context, options);
      if (result.errors) {
        console.error("Validation errors:", result.errors);
      }
      return result;
    },
    defaultValues: {
      sessionId: '',
      firstName: '',
      lastName: '',
      email: '',
      currentAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postcode: '',
      },
    },
  });

  const email = methods.watch('email');

  useEffect(() => {
    let storedSessionId = localStorage.getItem('sessionId');
    if (!storedSessionId) {
      storedSessionId = uuidv4();
      localStorage.setItem('sessionId', storedSessionId);
      onSubmit(false);
    }
    methods.setValue('sessionId', storedSessionId);
    logStepEvent(1); // Log the first step event on page load

    window.onpopstate = event => {
      if (event.state && typeof event.state.step === 'number') {
        setCurrentStep(event.state.step);
      }
    };

    window.history.replaceState({ step: 0 }, '');
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const logStepEvent = (stepNumber: number) => {
    const pageNames: { [key: number]: string } = {
      1: 'signupPageV1OneRequestFirstNameAndLastName',
      2: 'signupPageV1TwoRequestEmail',
      3: 'signupPageV1ThreeAskIfMovingHome',
      4: 'signupPageV1FourAskIfRentingOrBuying',
      5: 'signupPageV1FiveRequestCurrentAddress',
      6: 'signupPageV1SixOptionalRequestNextAddress',
      7: 'signupPageV1SevenRequestTermsAcceptanceAndSubmit',
    };

    const eventData = {
      eventType: TrackingEventType.SIGNUP_FORM_EVENT,
      data: {
        pageName: pageNames[stepNumber],
        stepNumber: stepNumber,
      },
    };

    logEvent(eventData);
  };

  const handleNext = () => {
    console.log('onSubmit', methods.getValues());
    onSubmit(false);
    const nextStep = currentStep + 1;
    logStepEvent(nextStep + 1);
    if (currentStep === 4 && !isMoving) return setCurrentStep(6);
    setCurrentStep(nextStep);
    window.history.pushState({ step: nextStep }, '');
  };

  const handlePrev = () => {
    const prevStep = currentStep - 1;
    logStepEvent(prevStep + 1);
    if (currentStep === 6 && !isMoving) return setCurrentStep(4);
    setCurrentStep(prevStep);
    window.history.pushState({ step: prevStep }, '');
  };

  const onSubmit = async (finalSubmit: boolean) => {
    console.log('onSubmit hit');
    const currentDate = new Date();
    const data = methods.getValues();

    setIsSending(true);
    setServerError('');
    setIsSuccess(false);

    try {
      await axios.post(
        ApiRoutes.SIGNUP,
        {
          sessionId: data.sessionId,
          data: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            ...(!!data.moveType && { type: data.moveType }),
            isMoving: isMoving,
            currentAddress: {
              addressLine1: data.currentAddress.addressLine1,
              ...(!!data.currentAddress.addressLine2 && {
                addressLine2: data.currentAddress.addressLine2,
              }),
              city: data.currentAddress.city,
              postcode: data.currentAddress.postcode,
            },
            ...(!!data.nextAddress && {
              nextAddress: {
                addressLine1: data.nextAddress.addressLine1,
                ...(!!data.nextAddress.addressLine2 && {
                  addressLine2: data.nextAddress.addressLine2,
                }),
                city: data.nextAddress.city,
                postcode: data.nextAddress.postcode,
              },
            }),
          },
          ...(data.terms &&
            !!finalSubmit && {
              termsAcceptedAt: data.terms,
              completedAt: currentDate.toISOString(),
            }),
        },
        {
          headers: {
            'content-type': 'application/json',
            'Home-Api-version': '2023-07-01',
          },
        }
      );

      if (!!finalSubmit) {
        setIsSuccess(true);
      }
    } catch (err) {
      console.log(err);
      setServerError('Something went wrong. Please try again.');
    } finally {
      setIsSending(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="mx-auto flex min-h-screen max-w-5xl flex-col items-center justify-between px-5">
        <header className="flex w-full py-5">
          <HomeLogo />
        </header>
        {!!isSuccess ? (
          <div
            className="animate-bounceIn flex h-full w-full flex-1 flex-col items-center justify-center gap-3 rounded-[20px] bg-home p-5"
            style={{ animation: 'bounceIn 650ms ease-in-out' }}
          >
            <div className="relative h-[76px] w-[89px]">
              <div
                style={{
                  animation: 'fadeInUp 0.4s ease-in-out 0.4s forwards',
                  opacity: 0,
                }}
              >
                <svg
                  width="89"
                  height="76"
                  viewBox="0 0 89 76"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute left-0 top-0"
                >
                  <path
                    d="M67.905 11.8494C69.0798 12.3091 70.5099 12.5645 70.9185 13.918C72.0421 16.8548 72.4507 19.9448 72.9359 23.0349C73.651 29.9811 74.1873 36.9784 74.1873 43.9502C74.1107 48.215 74.0341 52.4542 73.8808 56.719C73.7531 60.3198 74.4171 64.2526 72.3486 67.4448C71.7357 68.4408 70.612 68.5174 69.565 68.4919C56.2343 68.3386 42.8781 67.879 29.5475 68.2876C25.2316 68.3642 20.9158 68.4919 16.5744 68.5685C13.6886 68.6196 10.7773 68.6451 7.89159 68.6706C6.20611 68.6706 5.00584 67.8534 4.1631 66.4233C3.26928 65.0954 2.75853 63.5376 2.73299 61.9542C2.29885 52.6585 2.1967 43.3373 2.01794 34.0161C2.27331 31.2325 1.04751 24.7459 2.75853 22.8561C3.42251 22.2177 3.9588 21.9623 4.80154 22.5752C5.41444 22.9838 6.02734 23.3158 6.07842 24.1585C6.5381 28.551 6.48702 32.9435 6.56363 37.3615C6.51256 45.2271 6.58917 53.0927 6.84455 60.9583C7.04885 61.8265 6.64025 63.7163 7.84052 63.8185C23.7505 63.0268 39.6859 62.797 55.6214 62.8481C59.733 62.9247 63.819 63.0013 67.9306 63.2056C68.6712 63.2311 68.6712 63.2311 68.6201 62.465C68.4158 59.094 68.1349 55.7231 68.0327 52.3265C67.854 43.2862 68.2881 34.2459 68.1604 25.2311C68.0327 22.2177 68.1859 19.1787 67.6241 16.2163C67.8029 15.5268 65.0704 15.4757 64.8661 15.8843C57.7921 24.9502 50.4118 33.7352 42.4695 42.0349C38.3069 48.4193 17.0085 29.1384 12.9225 25.5631C-6.17965 6.76742 1.7881 11.594 21.733 11.0577C35.983 10.9811 50.4884 11.926 64.6107 11.3387C66.2451 10.0618 67.522 8.09537 69.4884 7.32924C71.5058 7.25263 68.3136 11.1344 67.905 11.8494ZM8.27466 14.9395C16.319 23.2137 25.0273 31.0026 34.6295 37.387C35.9064 37.9999 38.2048 40.0174 39.5583 39.3534C42.4951 36.008 45.2021 32.5093 48.0623 29.0873C51.944 24.3628 56.1066 19.9193 60.2948 15.4757C42.9548 16.5994 25.6147 15.5013 8.27466 14.9395Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div
                style={{
                  animation: 'popIn 0.5s ease-in-out 0.7s forwards',
                  opacity: 0,
                }}
              >
                <svg
                  width="89"
                  height="76"
                  viewBox="0 0 89 76"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute left-0 top-0"
                >
                  <circle cx="74.5" cy="41.5" r="14.5" fill="black" />
                  <path
                    d="M68.1756 42.8888C68.7811 43.2022 69.6413 44.6935 70.2388 44.3191C73.5775 40.9593 76.7233 36.3852 81.091 34.9092C80.8016 36.2513 80.0701 37.5143 79.2421 38.5794C76.5492 41.7262 74.0679 45.1408 71.0614 47.9255C70.0968 49.1367 66.9885 43.3179 68.1756 42.8888Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
            <h4 className="text-center text-3xl font-medium text-white">Check your mail</h4>
            <p className="max-w-lg text-center text-lg font-light text-white">
              We just sent an email to you at <b>{email}</b>. It has a magic link to finish securing
              your account.
            </p>
          </div>
        ) : (
          <form className="w-full max-w-lg" onSubmit={methods.handleSubmit(() => onSubmit(true))}>
            <NameStep onNext={handleNext} isActive={currentStep === 0} />
            <EmailStep onNext={handleNext} isActive={currentStep === 1} />
            <MovingSoonStep
              onNext={handleNext}
              isActive={currentStep === 2}
              setIsMoving={(val: boolean) => setIsMoving(val)}
            />
            <MoveTypeStep onNext={handleNext} isActive={currentStep === 3} isMoving={isMoving} />
            <CurrentAddressStep onNext={handleNext} isActive={currentStep === 4} />
            {!!isMoving && <NextAddressStep onNext={handleNext} isActive={currentStep === 5} />}
            <ConfirmAccount isSending={isSending} isActive={currentStep === 6} />
          </form>
        )}
        <footer className="flex w-full flex-col items-start gap-8 py-5">
          {currentStep > 0 && !isSuccess && (
            <button
              onClick={handlePrev}
              className="group flex items-center gap-3 rounded-full border border-primary bg-transparent px-5 py-2 transition-all hover:bg-primary hover:text-white"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 16 16"
                className="fill-primary group-hover:fill-white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16L9.41 14.59L3.83 9L16 9L16 7L3.83 7L9.41 1.41L8 -3.95616e-07L1.23639e-06 8L8 16Z"
                  fill="current"
                />
              </svg>
              <span>Go back</span>
            </button>
          )}
          {!isSuccess && (
            <div className="relative h-1 w-full bg-white">
              <div
                className={twMerge('h-1 bg-home transition-all duration-500')}
                style={{ width: `${((currentStep + 1) / totalSteps) * 100}%` }}
              />
            </div>
          )}
          <nav className="flex w-full flex-col items-center gap-4 text-sm font-medium md:flex-row md:gap-8">
            <span>&copy; Copyright 2024 Home. All rights reserved.</span>
            <a href={Routes.TERMS} className="hover:underline">
              Terms &amp; Conditions
            </a>
            <a href={Routes.PRIVACY} className="hover:underline">
              Privacy Policy
            </a>
          </nav>
        </footer>
      </div>
    </FormProvider>
  );
};

import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Button } from '@components/Button';

import { NewUser } from '..';
import { Step } from '../step';

interface StepProps {
  isSending: boolean;
  isActive: boolean;
}

export const ConfirmAccount = ({ isActive, isSending }: StepProps) => {
  const {
    control,
    formState: { isValid },
  } = useFormContext<NewUser>();

  if (!isActive) {
    return null;
  }

  return (
    <Step title="Create your account">
      <p className="text-center">We'll send you a magic link to confirm your email address</p>

      <Controller
        name="terms"
        control={control}
        render={({ field }) => (
          <label className="inline-flex cursor-pointer items-center space-x-3">
            <input
              type="checkbox"
              checked={!!field.value}
              onChange={e => field.onChange(e.target.checked ? new Date() : undefined)}
              className="flex h-5 w-5 cursor-pointer rounded border-2 border-primary bg-secondary accent-black outline-2 outline-primary checked:bg-primary checked:text-white"
            />
            <p className="font-medium">
              I have read and accept the{' '}
              <Link
                to="/terms"
                target="_blank"
                className="font-normal underline hover:no-underline"
              >
                terms and conditions.
              </Link>
            </p>
          </label>
        )}
      />
      <Button disabled={!isValid} isSubmitting={isSending}>
        Let's go
      </Button>
    </Step>
  );
};

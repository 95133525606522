import { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { Button } from '@components/Button';
import { SelectField } from '@components/ReactHookForm/Select/SelectField';
import { TextField } from '@components/ReactHookForm/TextField';
import { Spinner } from '@components/Spinner';

import { GET_ADDRESS_API_KEY } from '@constants/index';

import { useFetchAddressSuggestions } from '@hooks/useFetchAddressSuggestions';
import { useToast } from '@hooks/useToast';

import { NewUser } from '..';
import { Step } from '../step';

interface StepProps {
  onNext: () => void;
  isActive: boolean;
}

export const NextAddressStep = ({ onNext, isActive }: StepProps) => {
  const [showManualAddress, setShowManualAddress] = useState(false);
  const { setSearchTerm, isFetching, suggestions, error } = useFetchAddressSuggestions();
  const {
    control,
    formState: { errors },
    setValue,
    trigger,
    watch,
  } = useFormContext<NewUser>();
  const { dispatchErrorToast } = useToast();

  const postcodeSearchTerm = watch('nextAddress.searchTerm');

  useEffect(() => {
    if (postcodeSearchTerm) {
      setSearchTerm(postcodeSearchTerm);
    }
  }, [postcodeSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isActive) {
    return null;
  }

  const nextAddress = watch('nextAddress');

  const isButtonDisabled =
    !nextAddress || !nextAddress.addressLine1 || !nextAddress.city || !nextAddress.postcode;

  const getAddressDetails = async (id: string) => {
    try {
      const response: Response = await fetch(
        `https://api.getAddress.io/get/${id}?api-key=${GET_ADDRESS_API_KEY}`,
        {
          method: 'GET',
        }
      );

      const data = await response.json();

      if (data) {
        setValue('nextAddress.addressLine1', data.line_1);
        setValue('nextAddress.addressLine2', data.line_2);
        setValue('nextAddress.city', data.town_or_city);
        setValue('nextAddress.postcode', data.postcode);

        trigger('nextAddress');
      }
    } catch (error) {
      dispatchErrorToast('Something went wrong fetching address');
    }
  };

  return (
    <Step title="Where are you moving to?">
      <div className="flex w-full flex-col items-center gap-5">
        <div className="flex w-full flex-col items-center justify-center gap-1 sm:flex-row sm:justify-between sm:gap-8">
          <div className="relative flex flex-1 flex-col items-center justify-center gap-4 sm:flex-row sm:justify-start">
            <Controller
              name="nextAddress.searchTerm"
              control={control}
              render={({ field }) => (
                <TextField
                  id="nextSearch"
                  label="Search by postcode"
                  placeholder="Lookup postcode"
                  styles="col-span-1"
                  value={field.value}
                  onChange={e => {
                    const value = e.target.value;
                    field.onChange(value);
                    setSearchTerm(value);
                  }}
                  error={error}
                />
              )}
            />

            <span
              className={twMerge(
                'mt-4',
                !!isFetching
                  ? 'absolute -right-[40px] block opacity-100 sm:relative sm:right-auto'
                  : 'hidden opacity-0'
              )}
            >
              <Spinner colour="black" />
            </span>
          </div>

          <button
            type="button"
            className="col-span-1 mt-5 text-left underline hover:no-underline"
            onClick={() => setShowManualAddress(!showManualAddress)}
          >
            {!!showManualAddress ? 'Hide address' : 'Enter manually'}
          </button>
        </div>

        <div className="col-span-2 w-full">
          <Controller
            name="nextAddress.selectedAddress"
            control={control}
            render={({ field }) => (
              <SelectField
                id="nextAddress.selectedAddress"
                label="Address"
                options={[{ key: 'Select address', value: '', disabled: true }, ...suggestions]}
                placeholder="Select address"
                styles="w-full"
                disabled={suggestions.length === 0}
                defaultValue={field.value}
                onSelect={val => {
                  field.onChange(val);
                  getAddressDetails(val).then(() => {
                    setShowManualAddress(true);
                    setTimeout(() => setShowManualAddress(false), 1);
                  });
                }}
              />
            )}
          />
        </div>

        <div
          className={twMerge(
            'w-full overflow-hidden transition-all duration-500 ease-in-out',
            showManualAddress ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
          )}
        >
          {showManualAddress && (
            <div className="col-span-4 mt-5 flex w-full flex-col gap-y-5">
              <Controller
                name="nextAddress.addressLine1"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    id="addressLine1"
                    name="nextAddress.addressLine1"
                    label="Address Line 1"
                    placeholder="Address line 1"
                    error={errors.nextAddress?.addressLine1}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                name="nextAddress.addressLine2"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="addressLine2"
                    name="nextAddress.addressLine2"
                    label="Address Line 2"
                    placeholder="Address line 2"
                    error={errors.nextAddress?.addressLine2}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                name="nextAddress.city"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    id="nextAddress.city"
                    name="nextAddress.city"
                    label="City"
                    placeholder="City"
                    error={errors.nextAddress?.city}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <Controller
                name="nextAddress.postcode"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    id="nextAddress.postcode"
                    name="nextAddress.postcode"
                    label="Postcode"
                    placeholder="Postcode"
                    styles="w-1/2"
                    error={errors.nextAddress?.postcode}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
      <Button onClick={onNext} disabled={isButtonDisabled}>
        Next
      </Button>
    </Step>
  );
};

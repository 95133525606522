import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Button } from '@components/Button';
import { TextField } from '@components/ReactHookForm/TextField';

import { NewUser } from '..';
import { Step } from '../step';

interface StepProps {
  onNext: () => void;
  isActive: boolean;
}

export const NameStep = ({ onNext, isActive }: StepProps) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { register, getFieldState, watch } = useFormContext<NewUser>();

  const firstName = watch('firstName');
  const lastName = watch('lastName');

  const firstNameState = getFieldState('firstName');
  const lastNameState = getFieldState('lastName');

  useEffect(() => {
    if (
      (firstNameState.isDirty && firstNameState.invalid) ||
      (lastNameState.isDirty && lastNameState.invalid) ||
      !firstNameState.isDirty ||
      !lastNameState.isDirty
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [firstName, lastName]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isActive) {
    return null;
  }

  return (
    <Step title="Firstly, what shall we call you?">
      <div className="mb-4 flex w-full max-w-sm flex-col justify-center gap-5 sm:max-w-max sm:flex-row">
        <TextField
          {...register('firstName', { required: 'First name is required' })}
          type="text"
          id="firstName"
          label="First name"
        />
        <TextField
          {...register('lastName', { required: 'Last name is required' })}
          type="text"
          id="lastName"
          label="Last name"
        />
      </div>
      <Button onClick={onNext} disabled={isButtonDisabled}>
        Next
      </Button>
    </Step>
  );
};

import { Step } from '../step';

interface StepProps {
  onNext: () => void;
  isActive: boolean;
  setIsMoving: (isMoving: boolean) => void;
}

export const MovingSoonStep = ({ onNext, isActive, setIsMoving }: StepProps) => {
  if (!isActive) {
    return null;
  }

  return (
    <Step title="Are you planning to move home soon?">
      <div className="flex w-full flex-col gap-4">
        <button
          onClick={() => {
            setIsMoving(true);
            onNext();
          }}
          className="flex w-full items-center rounded-full bg-[#E2E7ED] px-4 py-3 text-left text-primary transition-all duration-300 hover:bg-primary hover:text-white"
        >
          <div className="mr-4 h-8 w-8 rounded-full bg-white shadow-xl"></div>
          Yes
        </button>
        <button
          onClick={() => {
            setIsMoving(false);
            onNext();
          }}
          className="flex w-full items-center rounded-full bg-[#E2E7ED] px-4 py-3 text-left text-primary transition-all duration-300 hover:bg-primary hover:text-white"
        >
          <div className="mr-4 h-8 w-8 rounded-full bg-white shadow-xl"></div>
          No
        </button>
      </div>
    </Step>
  );
};

import { useEffect, useRef } from 'react';
import { useLocation, Location } from 'react-router-dom';

export const ScrollToTop = () => {
  const location = useLocation();
  const lastLocation = useRef<Location | null>(null);

  useEffect(() => {
    const hash = location?.hash.slice(1);
    const pathSame = location.pathname === lastLocation.current?.pathname;

    if (hash) {
      setTimeout(() => {
        document
          .getElementById(hash)
          ?.scrollIntoView({ behavior: pathSame ? 'smooth' : 'auto', block: 'start' });
      }, 0);
    } else {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
      }, 0);
    }

    lastLocation.current = location;
  }, [location]);

  return null;
};

import { useFormContext } from 'react-hook-form';

import { MoveType } from '@features/profile/profile.types';

import { NewUser } from '..';
import { Step } from '../step';

interface StepProps {
  onNext: () => void;
  isActive: boolean;
  isMoving: boolean;
}

export const MoveTypeStep = ({ onNext, isActive, isMoving }: StepProps) => {
  const { setValue } = useFormContext<NewUser>();

  if (!isActive) {
    return null;
  }

  return (
    <Step title="Are you a renter or a home owner?">
      <div className="flex w-full flex-col gap-4">
        <button
          onClick={() => {
            setValue('moveType', MoveType.TENANCY);
            onNext();
          }}
          className="flex w-full items-center rounded-full bg-[#E2E7ED] px-4 py-3 text-left text-primary transition-all duration-300 hover:bg-primary hover:text-white"
        >
          <div className="mr-4 h-8 w-8 rounded-full bg-white shadow-xl"></div>
          Renting
        </button>
        <button
          onClick={() => {
            setValue('moveType', MoveType.BUYER);
            onNext();
          }}
          className="flex w-full items-center rounded-full bg-[#E2E7ED] px-4 py-3 text-left text-primary transition-all duration-300 hover:bg-primary hover:text-white"
        >
          <div className="mr-4 h-8 w-8 rounded-full bg-white shadow-xl"></div>
          Home owner
        </button>
      </div>
    </Step>
  );
};
